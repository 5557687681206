<template>
  <div>
    <v-app>
      <div class="row"
           :style="$vuetify.breakpoint.mobile ? 'padding-left: 0px;padding-right: 0px;padding-top: 20px' : 'padding-left: 20px;padding-right: 20px;padding-top: 20px'">
        <div style="float: left">
          <v-btn text x-large x-small @click="goToHome" style="padding: 0px">
            {{ $t('home') }}
          </v-btn>
          |
          <v-btn icon text x-large x-small @click="changeLanguage('km')"
                 :style="$vuetify.breakpoint.mobile ? 'margin-left: 1px' : 'margin-left: 15px'">
            <v-img src="@/assets/km.png" style="width: 30px; height: 30px"></v-img>
          </v-btn>
          |
          <v-btn icon text x-large x-small @click="changeLanguage('en')"
                 :style=" $vuetify.breakpoint.mobile ? 'margin-left: 1px' : 'margin-left: 15px'">
            <v-img src="@/assets/us.png" style="width: 30px; height: 30px"></v-img>
          </v-btn>

        </div>
        <v-spacer></v-spacer>
        <div style="float: right">
          <v-btn text x-large x-small @click="gotoEditData"
                 :style="$vuetify.breakpoint.mobile ? 'padding: 0px;' :'padding: 0px;' ">
            {{ $t('requestEdit') }}
          </v-btn>
        </div>
      </div>
      <v-main style="min-height: 300px;margin-bottom: 50px">
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import {Constants} from "@/libs/constant";
import MyMixin from "@/mixins/mixin"

export default {
  name: 'App',
  components: {},
  comments: {},
  mixins: [MyMixin],

  data() {
    return {
      languageList: Constants.languageList,
      languageListShort: Constants.languageListShort,
      searchClose: true,
      languageLabel: "En",
      fab: false,
      chatBtn: false,
      drawer: false,
      elv: 0,
      doc: {},
      plantTypeList: [],
      plantRoomList: [],
      plantLifeStyleList: [],
      plantGiftList: []
    }
  },
  methods: {
    gotoEditData() {
      let vm = this;
      vm.$router.push({
        name: "EditData"
      })
    },
    goToHome() {
      let vm = this;
      vm.$router.push({
        name: "Home"
      })
    },
    changeLanguage(val) {
      let vm = this;
      vm.$i18n.locale = val;
    },

    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20
      this.elv = top > 20 ? 20 : 0;
    },
    toTop() {
      this.$vuetify.goTo(0)
    },


  },
  created() {

  }
};

</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.fixTitle .v-toolbar__content {
  height: 40px !important;
}


.sub-1 {
  text-align: justify;
  font-family: "Khmer Kompong Trach", Verdana !important;
}

* {
  font-family: "Khmer Kompong Trach", Verdana !important;
}

.hover02 figure img {
  width: 95px;
  height: auto;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hover02 figure:hover img {
  width: 140px;
}

figure {
  width: 100px;
  height: 130px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #2b2e2e;
}


.ten_tagline:after, .ten_tagline--fill:after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100% - 12px);
  border-top: 1px solid rgba(25, 74, 195, 1);
}

.ten_tagline:before, .ten_tagline--fill:before {
  content: "";
  display: block;
  position: absolute;
  left: calc(100% - 12px);
  bottom: 0px;
  width: 100%;
  border-top: 1px solid rgba(25, 74, 195, 1);
  transform: rotate(
          -69deg) translate(-1px, 0px);
  transform-origin: 0px 0px;
}

.ten_tagline, .ten_tagline--fill {
  font-family: 'TencentSansw7';
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-top: 1px solid rgba(25, 74, 195, 1);
  padding: 0px 30px;
  color: rgba(25, 74, 195, 1);
}

.ten_tagline {
  border-left: 1px solid rgba(25, 74, 195, 1);
}

.bBottom {
  border-bottom: 1px solid #f4f4f4;
}

.deco-banner .artwork-bg {
  position: absolute !important;
  top: -420px;
  border-radius: 40px;
  transform: rotate(-10deg);
  width: 120%;
  overflow: hidden;
  height: 1024px;
  background-color: lightblue;
}

.deco-banner .artwork-bg1 {
  position: absolute !important;
  top: -420px;
  width: 100%;
  left: 0px;
  right: 0px;
  overflow: hidden;
  height: 1024px;
  background-color: lightblue;
}


// best seen at 1500px or less

/*body {
  background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}*/

.ocean {
  height: 0%;
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 180px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.section_tittle h2:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 80px;
  height: 3px;
  background-color: green;
}

.row {
  margin: 0px !important;
}

/*#create .v-speed-dial {
  position: absolute;
}*/

/*#create .v-btn--floating {
  position: relative;
}*/


@font-face {
  font-family: "Khmer Kompong Trach";
  src: url(assets/font/KHMKAMPONGTRACH.ttf);
}

@font-face {
  font-family: "LexiaBold";
  src: url(assets/font/Lexia_Std_XBold.ttf);
}

@font-face {
  font-family: "Lexia";
  src: url(assets/font/Lexia_Std_Rg.ttf);
}

@font-face {
  font-family: "Khmer OS Muol Light";
  src: url(assets/font/KhmerOSMuolLight.ttf);
}

.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  line-height: 1.9 !important;
}

.expanding-search > .v-input__control > .v-input__slot:before {
  border-color: transparent !important;
}

.expanding-search > .v-input__control > .v-input__slot:after {
  border-color: transparent !important;
  transform: scale(0) !important;
}

.close {
  max-width: 45px !important;
}

* {
  font-family: Lexia, "Khmer Kompong Trach";
}

.v-input input {
  max-height: 50px !important;
}

.v-text-field input {
  line-height: 35px !important;
}

.v-application--is-ltr .v-text-field .v-input__append-inner {
  margin-left: 32px !important;
}

.v-input .v-label {
  height: 50px !important;
  line-height: 32px !important;
  font-size: 22px !important;
}


.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 72px !important;
}
</style>
