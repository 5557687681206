let TabList = [
    {name: "home", to: "/"},
    {name: "allPlant", to: "/allPlant/All Plant"},
    {name: "about", to: "/about"},
    {name: "contact", to: "/contact"}
]
let LanguageList = [
    {name: "ភាសារខ្មែរ", value: "km"},
    {name: "English", value: "en"},
    {name: "中文", value: "cn"}
]
let LanguageListShort = [
    {name: "ខ្មែរ", value: "km"},
    {name: "En", value: "en"},
    {name: "中文", value: "cn"}
]
let MissionList = [
    {title: "mission1"},
    {title: "mission2"},
    {title: "mission3"},
    {title: "mission4"},
    {title: "mission5"}
]
let PhoneNumberList = [
    "010 480 762",
    "012 829 077",
    "087 985 282",
    "077 420 492"
]
let ActionOption = [
    {value: "ដំណើរការល្អធម្មតា", label: "ដំណើរការល្អធម្មតា"},
    {value: "ដំណើរការមិនសូវល្អ", label: "ដំណើរការមិនសូវល្អ"},
    {value: "មិនទាន់មាន", label: "មិនទាន់មាន"}];

export const Constants = {
    languageList: LanguageList,
    languageListShort: LanguageListShort,
    tabList: TabList,
    actionOption: ActionOption,

    missionList: MissionList,
    phoneNumberList: PhoneNumberList,
    token: "narongbilling",

    //Test
    /*baseURL: "http://localhost:3000/api/v1/",
    branchId: "yNf8Funx9t6sut5HW",*/


    //Live
    //baseURL: "http://157.245.196.88:6895/api/v1/",
    baseURL: "https://entry.oldercambodian.info/api/v1/",
    branchId: "yNf8Funx9t6sut5HW",

    api: {
        getHomePageDashboard: "homePageDashBoard/fetch",
        getAssociationByGeoOrName: "associationByGeoOrName/fetch",
        imageByType: "imageByType/fetch",
        getAssociationById: "associationById/fetch",
        getProvince: "province/fetch",
        getProvinceAss: "provinceAss/fetch",
        getDistrict: "district/fetch",
        getDistrictAss: "districtAss/fetch",
        getCommune: "commune/fetch",
        getCommuneAss: "communeAss/fetch",
        getVillage: "village/fetch",
        getVillageAss: "villageAss/fetch",
        getBranch: "branchById/fetch",
        getService: "service/fetch",
        requestUpdate: "requestUpdate/add",

    },

}