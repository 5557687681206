

export default {
    data() {
        return {
        }
    },
    methods: {

        paginate(array, page_size, page_number) {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        },

        getTranslate(data) {
            if (data) {
                if (this.$i18n.locale === 'en') {
                    return data.en;
                } else if (this.$i18n.locale === 'km') {
                    return data.km || data.en;
                }else if (this.$i18n.locale === 'cn') {
                    return data.cn || data.en;
                } else {
                    return data.km;
                }
            } else {
                return "";
            }
        },
    }
}